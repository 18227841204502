import request from '@/utils/request';

//批量切换系统变量查询
export async function fetchBatchSwitchSystemVariable() {
  return request(`/api/stores/one-click-switch-system-configs`, {
    method: 'GET',
  });
}

// 批量切换店铺范围查询
export async function fetchOneClickSwitchRange(params) {
  return request(`/api/stores/one-click-switch-range`, {
    method: 'GET',
    params,
  });
}

// 隐藏PP跟回滚接口
export async function storeHidePaypal(data) {
  return request(`/api/stores/hide-paypal`, {
    method: 'POST',
    data,
  });
}
// 信用卡优先跟回滚
export async function storePriorityCreditCard(data) {
  return request(`/api/stores/priority-credit-card`, {
    method: 'POST',
    data,
  });
}
// 一键关闭PP收款方式
export async function storeClosePaypal(data) {
  return request(`/api/stores/close-all-paypal`, {
    method: 'POST',
    data,
  });
}

// 绑定信用卡通道3
export async function bindCardThirdPayment(data) {
  return request(`/api/stores/bind_card3_payment`, {
    method: 'POST',
    data,
  });
}

// 更新信用卡通道3
export async function updateCardThirdPayment(data) {
  return request(`/api/stores/update_card3_payment`, {
    method: 'POST',
    data,
  });
}

// 关闭信用卡通道3
export async function closeCardThirdPayment(data) {
  return request(`/api/stores/close_card3_payment`, {
    method: 'POST',
    data,
  });
}

// 更新店铺统筹部备注
export async function updateStoreCoordinationRemark(id, data) {
  return request(`/api/stores/${id}`, {
    method: 'PATCH',
    data,
  });
}

// 更新店铺pp_rest_configs
export async function updatePPRestConfig(data) {
  return request(`/api/stores/update_pp_rest_configs`, {
    method: 'POST',
    data,
  });
}

// 绑定信用卡通道4
export async function bindCardFourthPayment(data) {
  return request(`/api/stores/bind_card4_payment`, {
    method: 'POST',
    data,
  });
}

// 关闭信用卡通道4
export async function closeCardFourthPayment(data) {
  return request(`/api/stores/close_card4_payment`, {
    method: 'POST',
    data,
  });
}

// 更新信用卡通道4
export async function updateCardFourthPayment(data) {
  return request(`/api/stores/update_card4_payment`, {
    method: 'POST',
    data,
  });
}

// 下挂站选项列表获取(所有绑定klarna的店铺下挂站)
export async function fetchAlreadyBindGatewayUrlList() {
  return request(`/api/stores/already-bind-gateway-url-list`, {
    method: 'GET',
  });
}

// 支付账号选项列表获取(所有绑定klarna的支付账号名称)
export async function fetchAlreadyBindConfigNameList() {
  return request(`/api/stores/already-bind-config-name-list`, {
    method: 'GET',
  });
}

// 批量开启分期付款
export async function batchOpenHirePurchaseTip(data) {
  return request(`/api/stores/batch-open-hire-purchase-tip`, {
    method: 'POST',
    data,
  });
}

// 批量关闭分期付款
export async function batchCloseHirePurchaseTip(data) {
  return request(`/api/stores/batch-close-hire-purchase-tip`, {
    method: 'POST',
    data,
  });
}

// 批量绑定本地支付ocean
export async function batchBindKlarnaOceanPay(data) {
  return request(`/api/stores/batch-bind-klarna-ocean-pay`, {
    method: 'POST',
    data,
  });
}

// 批量绑定本地支付sprout
export async function batchBindKlarnaSproutPay(data) {
  return request(`/api/stores/batch-bind-klarna-sprout-pay`, {
    method: 'POST',
    data,
  });
}

// 批量关闭本地支付
export async function batchCloseKlarnaLocalPayment(data) {
  return request(`/api/stores/close-all-local-payment`, {
    method: 'POST',
    data,
  });
}

// 开启快捷支付
export async function enableWalletQuickSetting(data) {
  return request(`/api/stores/enable-wallet-quick-setting`, {
    method: 'POST',
    data,
  });
}

// 关闭快捷支付
export async function disableWalletQuickSetting(data) {
  return request(`/api/stores/disable-wallet-quick-setting`, {
    method: 'POST',
    data,
  });
}

// 开启电子钱包
export async function bindStoreWallet(data) {
  return request(`/api/stores/bind-wallets`, {
    method: 'POST',
    data,
  });
}

// 关闭电子钱包
export async function closeWalletQuickSetting(data) {
  return request(`/api/stores/close-wallets`, {
    method: 'POST',
    data,
  });
}

// 批量绑定信用卡通道5
export async function bindCardFifthPayment(data) {
  return request(`/api/stores/bind_card5_payment`, {
    method: 'POST',
    data,
  });
}

// 批量关闭信用卡通道5
export async function closeCardFifthPayment(data) {
  return request(`/api/stores/close_card5_payment`, {
    method: 'POST',
    data,
  });
}
