import BrowerLogger from 'alife-logger';
import util from 'alife-logger/lib/util';
import commitId from '../config/commitId';
const { NODE_ENV } = process.env;

const release = commitId || 'undefined';

// 判断是否是本地环境
const isLocalHost = () => {
  const host = window.location.hostname;
  return host.includes('localhost') || host.includes('192.');
};

let aliLogger = window.aliLogger;
const isProduction = NODE_ENV === 'production';

const EnvironmentMap = {
  production: {
    sample: 20,
    pvSample: 20,
    environment: 'prod',
  },
  staging: {
    sample: 20,
    pvSample: 20,
    environment: 'pre',
  },
  test: {
    sample: 20,
    pvSample: 20,
    environment: 'daily',
  },
  development: {
    sample: 5,
    pvSample: 1,
    environment: 'local',
  },
};

const { sample, pvSample, environment } =
  EnvironmentMap[`${NODE_ENV}`] || EnvironmentMap['development'];

if (!aliLogger && !isLocalHost()) {
  try {
    // https://www.alibabacloud.com/help/zh/arms/browser-monitoring/developer-reference/sdk-reference#sc-instruction
    aliLogger = BrowerLogger.singleton({
      pid: 'jd07jw68wc@af11c218780879d',
      appType: 'web',
      imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
      sendResource: false,
      enableLinkTrace: true,
      behavior: true,
      disableHook: true,
      enableSPA: false,
      useFmp: false,
      disabled: false,
      sample,
      pvSample,
      environment,
      release,
      ignore: {
        // 自动收集的错误过滤规则
        ignoreErrors: [
          /ResizeObserver loop/,
          /ResizeObserver loop completed with undelivered notifications/,
          /^Script error\.?$/,
          error => {
            if (typeof error === 'string') {
              return error.includes('ResizeObserver');
            }
            return error?.message?.includes('ResizeObserver');
          },
        ],
        // 手动上报的错误过滤规则
        __ignoreErrors: [
          ...(aliLogger?._conf?.ignore?.__ignoreErrors || []), // 保留现有规则
          (message, error) => {
            const errorMsg = error?.error?.message || message || '';
            return errorMsg.includes('ResizeObserver');
          },
        ],
      },
    });

    if (aliLogger) {
      aliLogger.pipe = ['setPage', location?.pathname];
    }
  } catch (error) {
    // 运行错误
  }
}

export const reportAPI = ({ url, success, time, code, message, begin, traceId, sid }) => {
  if (aliLogger) {
    aliLogger.api(url, success, time, code, message, begin, traceId, sid);
  }
  if (!isProduction) {
    console.log('[REPORT_API]', [url, success, time, code, message, begin, traceId, sid]);
  }
};

// 上报 JS 错误
export const reportError = ex => {
  /**
   * !NOTE: 参考源码
   * ./node_modules/alife - logger / lib / reporter.js@59行
   * ./ node_modules / alife - logger / lib / base.js@133行
   * ./ node_modules / alife - logger / lib / util.js
   */
  const myUtil = {
    ...util,
    // 重写源码中的 ignoreByRule，添加 原始错误对象的支持
    ignoreByRule: function(e, t, originError) {
      if (!e || !t) return !1;
      if (
        ((this.isString(t) || t.source || 'Function' === this.T(t)) && (t = [t]), !this.isArray(t))
      )
        return (
          this.warn(
            '[arms] invalid rules of ignore config, (list of) String/RegExp/Funcitons are available',
          ),
          !1
        );
      for (var r, n = [], o = 0, i = t.length; o < i; o++)
        if (((r = t[o]), this.isString(r)))
          // eslint-disable-next-line no-useless-escape
          n.push(r.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'));
        else if (r && r.source) n.push(r.source);
        else if (r && 'Function' === this.T(r) && !0 === this.safetyCall(r, [e, originError], !1))
          return !0;
      var a = new RegExp(n.join('|'), 'i');
      return !!(n.length && a.test && a.test(e));
    },
  };

  const r = ex.error.name || 'CustomError';
  const i = ex.error.message;
  const a = ex.error.stack;
  const t = ex;

  // 自定义错误拦截，如果没过就不上报
  const customError = aliLogger._conf.ignore.__ignoreErrors;
  const ignore = myUtil.ignoreByRule(i, customError, t);

  // 非生产环境打印一下，方便调试
  if (!isProduction) {
    console.log(`[REPORT_ERROR] [${r}] ${ignore ? 'Ignore' : 'Report'}`, [
      myUtil.ignoreByRule,
      i,
      customError,
      t,
    ]);
  }

  if (ignore) {
    return;
  }

  const s =
    ('object' === typeof location &&
      'string' === typeof location.href &&
      location.href.substring(0, 500)) ||
    '';
  const c = {
    begin: Date.now(),
    cate: r,
    msg: i && i.substring(0, 1e3),
    stack: a && a.substring(0, 1e3),
    file: myUtil.removeUrlSearch(t.filename || ''),
    line: t.lineno || '',
    col: t.colno || '',
    err: {
      msg_raw: myUtil.encode(i),
      stack_raw: myUtil.encode(a),
    },
    dl: s,
  };

  for (let l = ['tag', 'c1', 'c2', 'c3'], p = 0; p < l.length; p++) {
    const f = l[p];
    if (t[f]) {
      c[f] = t[f];
    }
  }

  aliLogger.beforeSend('error', c);
  const tempIgnoreErrors = aliLogger._conf.ignore.ignoreErrors;
  // 自定义上报错误时，手动关闭上报时二次拦截
  aliLogger._conf.ignore.ignoreErrors = [];
  aliLogger._lg('error', c, 1);
  // 还原
  aliLogger._conf.ignore.ignoreErrors = tempIgnoreErrors;
};

export default aliLogger;
