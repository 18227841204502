import { fetchCurUser, fetchRate, fetchRateOptions } from '@/services/api';
import { mockUser } from '@/services/debug';
import { setToken } from '@/utils/common';

import { setUser } from '@/utils/authority';
import permissions from '@/utils/permissions';

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {
      handleJudgeAuth: () => [], // 判定用户是否具有某权限 ([p])=>[isP]
      hasPermission: () => false, // 单一权限判断 (p)=>isP
      hasAnyPermission: () => false, // 判断是否有任意一个权限 (...p) => isP
      handleJudgePartOrPeople: () => ({
        isAdmin: false,
        isGM: false,
        isGroup: false,
        isShowCompany: false,
        isShowPart: false,
        isShowPeople: false,
        isShowPartPeople: false,
      }), // 聚合权限判断

      // 关联用户
      associationUsers: () => {},
    },
    rate: {
      data: [],
      total: 0,
    },
    rateOptions: {},
    processCount: 0,
    violating_product_count: 0,
    unread_count: 0,
  },
  effects: {
    *fetchCurrent({ callback }, { call, put }) {
      const response = yield call(fetchCurUser);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getRate({ payload, callback }, { call, put }) {
      const response = yield call(fetchRate, payload);
      yield put({
        type: 'saveRate',
        payload: response,
      });
      if (callback) callback();
    },
    *getRateOptions({ payload, callback }, { call, put }) {
      const response = yield call(fetchRateOptions, payload);
      yield put({
        type: 'saveRateOptions',
        payload: response,
      });
      if (callback) callback();
    },
    *changeProcessCount({ payload }, { put }) {
      yield put({
        type: 'saveProcessCount',
        payload,
      });
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      const currentUser = action.payload || {};
      setUser(currentUser);
      currentUser.hasPermission = permission => currentUser?.permissions?.includes(permission);
      currentUser.hasAnyPermission = (...permissions) =>
        permissions.some(i => currentUser?.permissions?.includes(i));
      currentUser.handleJudgeAuth = permissionsList =>
        permissionsList.map(currentUser?.hasPermission);
      currentUser.handleJudgePartOrPeople = () => {
        const {
          ADMIN,
          SHOW_COMPANY,
          SHOW_PART,
          SHOW_PEOPLE,
          PART,
          PEOPLE,
          SHOW_PART_PEOPLES,
          DEBUG,
        } = permissions;
        const isAdmin = currentUser?.hasPermission(ADMIN);
        const isGM = currentUser?.hasPermission(PEOPLE);
        const isGroup = currentUser?.hasPermission(PART) || currentUser?.hasPermission(PEOPLE);
        const isShowCompany = currentUser?.hasPermission(SHOW_COMPANY);
        const isShowPart = isShowCompany || currentUser?.hasPermission(SHOW_PART);
        const isShowPeople = isShowPart || currentUser?.hasPermission(SHOW_PEOPLE);
        const isShowPartPeople = currentUser.hasPermission(SHOW_PART_PEOPLES);
        if (currentUser.hasPermission(DEBUG)) {
          window.__app = {
            mockUser: function(name) {
              mockUser({ name })
                .then(res => {
                  setToken(res.accessToken);
                  location.reload();
                })
                .catch(err => {
                  console.warn('Mock failed: ', err.message);
                });
            },
          };
        }
        return {
          isGM,
          isGroup,
          isAdmin,
          isShowCompany,
          isShowPeople,
          isShowPart,
          isShowPartPeople,
        };
      };
      currentUser.getClass = () => {
        // 阶级: Company | Part | People
        if (currentUser.people_id) return 'People';
        if (currentUser.part_id) return 'Part';
        return 'Company';
      };
      return {
        ...state,
        currentUser,
        processCount: action.payload.need_process_count,
        violating_product_count: action.payload.violating_product_count,
        unread_count: action.payload.unread_count,
      };
    },
    saveRate(state, action) {
      const { data = [], total_count: total } = action.payload;
      return {
        ...state,
        rate: {
          data,
          total: total || 0,
        },
      };
    },
    saveRateOptions(state, action) {
      return {
        ...state,
        rateOptions: action.payload || {},
      };
    },
    saveProcessCount(state, action) {
      return {
        ...state,
        processCount: action.payload,
      };
    },
    clearCurrentUser(state) {
      setUser({});
      return { ...state, currentUser: {} };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
    saveViolatingProductCount(state, action) {
      return { ...state, violating_product_count: action.payload.violating_product_count };
    },
    saveUnreadCount(state, action) {
      return { ...state, unread_count: action.payload.unread_count };
    },
  },
};
export default UserModel;
